<template>
  <div id="main-wrapper">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'FullPage',
  beforeMount() {}
}
</script>

<style scoped></style>
